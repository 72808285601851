import React from "react";

const Checkbox  = ({ label, name, required, className, checked, onChange }) => {

    return (
        <div className={`checkbox-wrapper ${className}`}>
            <label>
                <input type="checkbox" name={name} checked={checked} onChange={onChange} required={required} />
                <span className="checkmark"> </span>
                {label}
            </label>
        </div>
    )

};

export default Checkbox;