import React from "react";
import "./preloader.scss";

const Preloader = ({enabled}) => {

    const preloader = (enabled) ? enabled: false;

    return preloader && (
        <>
            <div id="overlayer" />
            <div className="loader-container">
                <span className="loader">
                    <span className="loader-inner" />
                </span>
                <div className="loader-txt">Processing...</div>
            </div>
        </>
    )

};

export default Preloader;
