import React from 'react'
import "./_Select.scss"

const Select = (props) => {

    return (
        <select name={props.name}
                placeholder={props.placeholder}
                className='hsl-select'
                onChange={props.onChange}
                disabled={props.disabled}
                required={props.required}
        >
            <option value={props.value.option}>{props.value.label}</option>
            {props.options.length && (
                props.options.map(item => <option value={item.value}>item.label</option>)
            )}
        </select>
    )

};

export default Select;
