import React, {useState} from 'react';
import 'react-day-picker/lib/style.css';
import Calender from "../../components/calender/calender";
import TimePicker from "../../components/timepicker/timepicker";

import "./_eventForm.scss"

const EventForm = ({prevStep, nextStep, state, handleStepChanges}) => {

    const [error, setError] = useState({});
    const {selectedDay, selectedTime} = state.calendar;

    const Continue = async (e) => {
        e.preventDefault();

        (selectedDay && selectedTime) ? nextStep() : setError({selectedDay: 'Please select date and time first'});

    };

    const Return = (e) => {
        e.preventDefault();
        prevStep();
    };

    return (
        <>
            <div className="checkout-book-day-component">
                <h5 className="heading">Book your Day</h5>
                <div className="checkout-book-day-row">
                    <Calender state={state} handleStepChanges={handleStepChanges}/>
                    <TimePicker state={state} handleStepChanges={handleStepChanges}/>
                </div>
                <div style={{'padding': '20px 0', 'textAlign': 'center'}}>
                    {error && (
                        <small className="with-errors">{error.selectedDay}</small>
                    )}
                </div>
            </div>

            <div className="checkout-nav">
                <button type="button" className="btn btn-prev" onClick={Return}><i
                    className="font-icon arrow-right"> </i> Back
                </button>
                <button type="button" className="btn btn-next" onClick={Continue}>Continue</button>
            </div>
        </>
    )
};

export default EventForm;
