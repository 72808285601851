import React from 'react'
import "./_forms.scss"

const FormField = ({ label, type, name, placeholder, required, value, pattern, defaultValue, errors, touched, isSubmitting, onChange, onBlur, readonly }) => {

    return (
        <>
            <div className={errors && (touched.indexOf(name)!==-1 || isSubmitting) ? 'form-control invalid': 'form-control'}>
                <label htmlFor={name}>
                    <input name={name}
                           type={type}
                           placeholder={placeholder}
                           value={value}
                           pattern={pattern}
                           defaultValue={defaultValue}
                           onChange={onChange}
                           onBlur={onBlur}
                           required={required}
                           readOnly={readonly}
                    />
                </label>
                {errors && (touched.indexOf(name)!==-1 || isSubmitting) && (
                    <small className="help-block with-errors">{errors}</small>
                )}
            </div>
        </>
    );
};

export default FormField;
