import React from "react";
import Description from "../../components/description/description";
import StepProjectDetails from "../projectDetails/stepProjectDetails";
import "./_details-combined.scss";

const DetailsCombined = ({state, nextStep, handleStepChanges}) => {

    return (
        <div className='details-combined'>
            <Description type={state.product.type} />

            <StepProjectDetails
                handleStepChanges={handleStepChanges}
                nextStep={nextStep}
                handleChange={handleStepChanges}
                state={state}
            />
        </div>
    )

}

export default DetailsCombined;
