import React from "react";
import ProductPhotosOpt1 from "./product-photography/ProductPhotosOpt1";
import ProductPhotosOpt2 from "./product-photography/ProductPhotosOpt2";
import ProductPhotosOpt3 from "./product-photography/ProductPhotosOpt3";

const ProductPhotographyDescription = ({uid}) => {

    switch (uid) {
        case 'ProductPhotosOpt1':
            return <ProductPhotosOpt1/>;
        case 'ProductPhotosOpt2':
            return <ProductPhotosOpt2/>;
        case 'ProductPhotosOpt3':
            return <ProductPhotosOpt3/>;
        default:
            return <h1>No project match</h1>
    }

}

export default ProductPhotographyDescription;
