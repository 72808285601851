import React from "react";

const IncrementButton = ({ label, name, onClick }) => {

    return (
        <button type="button" onClick={onClick}>+</button>
    );
};

export default IncrementButton;
