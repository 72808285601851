import React from "react";
import "./_slotList.scss"

const SlotList = ({period, state, handleStepChanges}) => {

    const interval = parseInt(period);
    const startFrom = 9;
    const language = window.navigator.language
    const date = new Date();
    const format = {
        hour: 'numeric',
        minute: 'numeric',
    };

    const generateTimeList = (startFrom) => {
        const slotArr = [];
        for (let minutes = 0; minutes < 9 * 60; minutes = minutes + interval) {
            date.setHours(startFrom);
            date.setMinutes(minutes);
            slotArr.push(date.toLocaleTimeString(language, format));
        }
        return slotArr;
    };

    const ranges = generateTimeList(startFrom);

    const handleSlotClick = (e) => {
        handleStepChanges('calendar', {
            selectedTime: e.target.textContent
        })
    };

    const activeSlot = (slot) => {

        let cssClass = 'slotItem';

        return slot === state.calendar.selectedTime ? cssClass += ' active': cssClass;
    };

    return (
        <div className="slotList">
            {ranges.map((item, index) => (
                    <div className={activeSlot(item)} role="button" tabIndex={index} onClick={handleSlotClick}
                         key={index}>{item}
                    </div>
                )
            )}
        </div>
    );

};
export default SlotList;
