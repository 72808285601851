import React from "react"
import DayPicker from "react-day-picker";

import "../../components/calender/_calender.scss"

import account from "../../../../../static/svg/icons/account_circle.svg"

const Calender = ({state, handleStepChanges}) => {

    const toDay = new Date();
    const toMonth = toDay.setMonth(toDay.getMonth() + 1);
    const tomorrow = new Date(toDay);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const modifiers = {
        today: new Date(),
    };

    const handleDayClick = (day, modifiers = {}) => {
        if (modifiers.disabled) {
            alert('Those dates are not available')
        } else {
            handleStepChanges('calendar', {
                selectedDay: day
            })
        }
    };

    return (

        <div className="checkout-calender">
            <img src={account} width="90" height="90" alt=""/>
            <div className="calender-title">Book Your Studio Rental</div>
            <DayPicker
                fromMonth={new Date()}
                toMonth={new Date(toMonth)}
                fixedWeeks
                disabledDays={[
                    {before: new Date()},
                    {daysOfWeek: [0, 6]}
                ]}
                selectedDays={state.calendar.selectedDay}
                modifiers={modifiers}
                onDayClick={handleDayClick}
            />
        </div>
    )

};

export default Calender;
