import React from "react";
import "./_timepicker.scss"
import SlotList from "../slotList/slotList";

const TimePicker = ({state, handleStepChanges}) => {

    const minutes = 60 * state.product.hours;

    return (
        <div className="checkout-time-select">
            <div className="form-group">
                <label htmlFor="timeInterval">How long do you need?</label>
                <div id="timeInterval">
                    {state.product.hours > 1 &&
                        <>
                            {state.product.hours} hours
                        </>
                    }
                    {state.product.hours === 1 &&
                        <>
                            {state.product.hours} hour
                        </>
                    }
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="timeZone">What time works best?</label>
                <select name="timeZone" id="timeZone">
                    <option value="-7">UTC -07:00 Pacific Time</option>
                    <option value="-6">UTC -06:00 Mountain Time</option>
                    <option value="-5">UTC -05:00 Central Time</option>
                    <option value="-4">UTC -04:00 Eastern Time</option>
                    <option value="-3">UTC -03:00 Atlantic Time</option>
                </select>
            </div>
            <SlotList period={minutes} state={state} handleStepChanges={handleStepChanges} />
        </div>
    )

}

export default TimePicker;
